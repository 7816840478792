<template>
  <div ref="form">
    <v-card>
      <v-card-title> 分配资产 </v-card-title>
      <v-card-text>
        <v-select
          dense
          class="mr-2"
          v-model="form.user_id"
          :items="userItems"
          item-text="username"
          item-value="id"
          label="用户"
        ></v-select>

        <v-select
          dense
          multiple
          class="mr-2"
          v-model="form.identifier"
          :items="items"
          item-text="name"
          item-value="identifier"
          label="资产"
        ></v-select>
        <div>
          <v-btn
            :loading="loading"
            depressed
            color="primary"
            block
            @click="submit"
          >
            分配
          </v-btn>
          <v-btn depressed class="mt-1" block @click="cancel"> 取消 </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h7 lighten-2"> 修改域名结尾 </v-card-title>
        <v-text-field
          style="padding-left: 20px; padding-right: 20px"
          label="域名结尾"
          v-model="domainE"
        ></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="dialog = false"> 确认</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { da } from "date-fns/locale";

export default {
  name: "",
  props: [""],
  components: {},
  data() {
    return {
      loading: false,
      form: {
        user_id: 0,
        identifier: [],
      },
      items: [],
      userItems: [],
      dialog: false,
    };
  },
  methods: {
    submit() {
      if (this.form.user_id > 0 && this.form.identifier.length > 0) {
        this.loading = true;
        let assets = [];
        this.form.identifier.forEach((identifier) => {
          let item = {
            identifier: identifier,
          };
          assets.push(item);
        });
        let data = {
          user_id: this.form.user_id,
          assets: assets,
        };
        this.$axios
          .post("/trunkserver/assets/allocate/", data)
          .then((res) => {
            if (res.data.code == 0) {
              this.$message.success("分配资产成功");
              this.$router.push({
                path: "/organ/allocate",
              });
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      } else {
        this.$message.error("必填项未勾选！");
      }
    },
    cancel() {
      this.disabled = false;
      sessionStorage.setItem("listId", 0);
      this.$router.push({
        path: "/domain/list",
      });
    },
    getDomainList() {
      this.$axios
        .get(`/trunkserver/assets/entity/?page=1&&size=10000`)
        .then((res) => {
          console.log(res);
          if (res.data.results && res.data.results.length > 0) {
            this.items = res.data.results;
          }
          console.log(this.items);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUserList() {
      this.$axios
        .get(`/trunkserver/accounts/user-list/`)
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.userItems = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getDomainList();
    this.getUserList();
  },
};
</script>

<style scoped>
.item {
  display: flex;
  flex-direction: row;
}
.btnW {
  width: 10%;
}
</style>
